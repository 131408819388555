var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("div", { staticClass: "page-search-bar" }, [
        _c("div", { staticClass: "app-name" }, [
          _c("span", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("lang_apply_name")) +
                "：" +
                _vm._s(_vm.appName)
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "app-tools" },
          [
            _c("el-button", {
              attrs: {
                type: "primary",
                size: "small",
                icon: "el-icon-arrow-left",
              },
              on: { click: _vm.callback },
            }),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: "新建",
                  placement: "top-start",
                },
              },
              [
                _c("el-button", {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-plus",
                  },
                  on: { click: _vm.addData },
                }),
              ],
              1
            ),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: "刷新",
                  placement: "top-start",
                },
              },
              [
                _c("el-button", {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-refresh",
                  },
                  on: { click: _vm.changeTab },
                }),
              ],
              1
            ),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  content: _vm.$t("lang_publish_application"),
                  effect: "dark",
                  placement: "top-start",
                },
              },
              [
                _c("el-button", {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-s-promotion",
                  },
                  on: { click: _vm.publishApp },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-content" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.changeTab },
              model: {
                value: _vm.tabsActiveName,
                callback: function ($$v) {
                  _vm.tabsActiveName = $$v
                },
                expression: "tabsActiveName",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("lang_form_configuration"),
                    name: "form",
                  },
                },
                [
                  _vm.tabsActiveName === "form"
                    ? [
                        _c(
                          "el-table",
                          {
                            staticClass: "page-table",
                            attrs: {
                              "empty-text": _vm.$t("lang_no_data"),
                              border: "",
                              data: _vm.formList,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_name"),
                                align: "center",
                                prop: "link.displayName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_describe"),
                                align: "center",
                                prop: "link.displayDescription",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_edition"),
                                align: "center",
                                prop: "link.version",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_state"),
                                align: "center",
                                prop: "link.status",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.link.status == 0
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "primary" } },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("lang_edit_z"))
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        scope.row.link.status == 1
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "success" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("lang_published")
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        scope.row.link.status == 2
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "danger" } },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("lang_closed"))
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2069168051
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_operation"),
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.link.status == 0
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  color: "#007aff",
                                                },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editForm(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("lang_edit")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        scope.row.link.status == 0
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  color: "#007aff",
                                                },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.designForm(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("lang_design")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        scope.row.link.status == 0
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  color: "#007aff",
                                                },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.releaseForm(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("lang_release")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        scope.row.link.status == 1
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  color: "#007aff",
                                                },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.upgradeForm(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "lang_application_manage_upgrade"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { color: "#007aff" },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.historyForm(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "lang_historical_version"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        scope.row.link.status == 0
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: { color: "red" },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteForm(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("lang_delete")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                94882022
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("lang_process_configuration"),
                    name: "flow",
                  },
                },
                [
                  _vm.tabsActiveName === "flow"
                    ? [
                        _c(
                          "el-table",
                          {
                            staticClass: "page-table",
                            attrs: {
                              "empty-text": _vm.$t("lang_no_data"),
                              border: "",
                              data: _vm.flowList,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_name"),
                                align: "center",
                                prop: "link.displayName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_edition"),
                                align: "center",
                                prop: "link.version",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_state"),
                                align: "center",
                                prop: "link.status",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.link.status == 0
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "primary" } },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("lang_edit_z"))
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        scope.row.link.status == 1
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "success" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("lang_published")
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        scope.row.link.status == 2
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "danger" } },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("lang_closed"))
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2069168051
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_creation_time"),
                                align: "center",
                                prop: "link.createDate",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.DateUtil.format(
                                                new Date(row.link.createDate),
                                                "yyyy-MM-dd hh:mm:ss"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                260923267
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_modification_time"),
                                align: "center",
                                prop: "link.updateDate",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.DateUtil.format(
                                                new Date(row.link.updateDate),
                                                "yyyy-MM-dd hh:mm:ss"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1162233974
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_operation"),
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.link.status == 1
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  color: "#007aff",
                                                },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editFlowBaseInfo(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "lang_application_flow_config_edit_basic_infomation"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  color: "#007aff",
                                                },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editFlow(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("lang_edit"))
                                                ),
                                              ]
                                            ),
                                        scope.row.link.status == 0
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  color: "#007aff",
                                                },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.designFlow(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("lang_design")
                                                  ) + " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        scope.row.link.status == 1
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  color: "#007aff",
                                                },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.upgradeFlow(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "lang_application_manage_upgrade"
                                                    )
                                                  ) + " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        scope.row.link.status == 1
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  color: "#007aff",
                                                },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.startupFlow(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "lang_flow_log_status_launch"
                                                    )
                                                  ) + " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { color: "#007aff" },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.historyFlow(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "lang_historical_version"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        scope.row.link.status == 0
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: { color: "red" },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteFlow(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("lang_delete")
                                                  ) + " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                259388203
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("lang_print_configuration"),
                    name: "report",
                  },
                },
                [
                  _vm.tabsActiveName === "report"
                    ? [
                        _c(
                          "el-table",
                          {
                            staticClass: "page-table",
                            attrs: {
                              "empty-text": _vm.$t("lang_no_data"),
                              border: "",
                              data: _vm.reportList,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_name"),
                                align: "center",
                                prop: "name",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_describe"),
                                align: "center",
                                prop: "description",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_creation_time"),
                                align: "center",
                                prop: "createDate",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.DateUtil.format(
                                                new Date(row.createDate),
                                                "yyyy-MM-dd hh:mm:ss"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4028671597
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_sort"),
                                align: "center",
                                prop: "sort",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_operation"),
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { color: "#007aff" },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editReport(scope.row)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("lang_edit"))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { color: "red" },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeReport(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("lang_delete"))
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                762926445
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("form-config", {
        attrs: {
          applicationId: _vm.appId,
          visible: _vm.formConfigDiglogVisible,
        },
        on: {
          "update:visible": function ($event) {
            _vm.formConfigDiglogVisible = $event
          },
          success: _vm.changeTab,
        },
      }),
      _c("flow-config", {
        attrs: {
          applicationId: _vm.appId,
          visible: _vm.flowConfigDialogVisible,
          flowConfig: _vm.currFlowData,
          flowId: _vm.currFlowData.id,
        },
        on: {
          "update:visible": function ($event) {
            _vm.flowConfigDialogVisible = $event
          },
          "update:flowConfig": function ($event) {
            _vm.currFlowData = $event
          },
          "update:flow-config": function ($event) {
            _vm.currFlowData = $event
          },
          saved: _vm.changeTab,
        },
      }),
      _c("flow-base-info-config", {
        attrs: {
          applicationId: _vm.appId,
          visible: _vm.flowBaseInfoConfigDialogVisible,
          flowConfig: _vm.currFlowData,
        },
        on: {
          "update:visible": function ($event) {
            _vm.flowBaseInfoConfigDialogVisible = $event
          },
          "update:flowConfig": function ($event) {
            _vm.currFlowData = $event
          },
          "update:flow-config": function ($event) {
            _vm.currFlowData = $event
          },
          saved: _vm.changeTab,
        },
      }),
      _c("report-config", {
        attrs: {
          visible: _vm.reportConfigDiglogVisible,
          applicationId: _vm.appId,
          reportId: _vm.reportId,
        },
        on: {
          "update:visible": function ($event) {
            _vm.reportConfigDiglogVisible = $event
          },
          success: _vm.changeTab,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }