




































































































































































import { Component, ProvideReactive, Vue } from 'vue-property-decorator';
import AppApi from '@/api/application/AppApi';
import ReportApi from '@/api/report-design/ReportApi';
import { FormModel } from '@/models/form/FormModel';
import { FlowData, FlowModel } from '@/models/flow/FlowModel';
import StoreIndex from '@/store/StoreIndex';
import { getFormConfig } from '@/api/form-design/FormApi';
import FormConfig from '@/components/form-design/config/form-config.vue';
import FlowConfig from '@/components/flow-design/flow-config/flow-config.vue';
import FlowBaseInfoConfig from '@/components/flow-design/flow-config/flow-base-info-config.vue';
import ReportConfig from '@/components/report-design/report-config.vue';
import DateUtil from 'global-ui/packages/utils/DateUtil';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';

@Component({
  name: 'AppDetail',
  components: {
    FormConfig,
    FlowConfig,
    FlowBaseInfoConfig,
    ReportConfig
  }
})
export default class AppDetail extends Vue {
  DateUtil: any = DateUtil;

  // 表单搜索Model
  formSearchModel: any = { name: '' };

  // tabs选中的name
  tabsActiveName: string = 'form';

  // 应用名
  appName: string = '';
  // 应用id
  appId: any = this.$route.query.appId;

  // 表单列表
  formList: FormModel[] = [];
  // 流程列表
  flowList: FlowModel[] = [];
  // 当前流程实体
  currFlowData: FlowModel = SystemUtil.cloneDeep(FlowData);
  // 表单配置弹窗
  formConfigDiglogVisible: boolean = false;
  // 流程配置弹窗
  flowConfigDialogVisible: boolean = false;
  // 流程基本信息配置弹窗
  flowBaseInfoConfigDialogVisible: boolean = false;

  // 打印配置列表
  reportList: any[] = [];
  reportId: string = '';
  reportConfigDiglogVisible: boolean = false;

  @ProvideReactive() formId: any = null;

  /**
   * onMounted
   */
  mounted() {
    // 如果appid没有则跳转到列表页
    if (!this.appId) {
      this.callback();
    }

    // 获取应用详情
    this.getAppDetail();

    // 获取应用表单/流程列表
    this.changeTab();
  }

  /**
   * 获取应用详情
   */
  getAppDetail() {
    AppApi.detail({ applicationId: this.appId }).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        this.appName = res.data.displayName;
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 发布应用
   */
  publishApp() {
    let appId = this.appId;
    if (appId) {
      this.$confirm(this.$t('lang_are_you_sure_to_release_it') as string, this.$t('lang_tips') as string, {
        confirmButtonText: this.$t('lang_determine_') as string,
        cancelButtonText: this.$t('lang_cancel_') as string,
        type: 'warning'
      }).then(() => {
        AppApi.publishApp(appId).then((res: Record<any, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            this.getFlowListData();
          } else {
            this.$message.error(res.message);
          }
        });
      });
    }
  }

  /**
   * 新建表单或流程
   */
  addData() {
    if (this.tabsActiveName === 'form') {
      this.formId = '';
      this.formConfigDiglogVisible = true;
    } else if (this.tabsActiveName === 'flow') {
      this.currFlowData = SystemUtil.cloneDeep(FlowData);
      this.flowConfigDialogVisible = true;
    } else if (this.tabsActiveName === 'report') {
      this.reportId = '';
      this.reportConfigDiglogVisible = true;
    }
  }

  /**
   * 获取应用表单列表
   */
  getFormListData() {
    AppApi.getApplicationLinkFormList({ applicationId: this.appId }).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        this.formList = res.data;
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 删除表单
   * @param row
   */
  deleteForm(row) {
    if (row) {
      this.$confirm(this.$t('lang_whether_or_not_to_delete') as string, this.$t('lang_tips') as string, {
        confirmButtonText: this.$t('lang_determine_') as string,
        cancelButtonText: this.$t('lang_cancel_') as string,
        type: 'warning'
      })
        .then(() => {
          AppApi.deleteAppLink(row.id)
            .then((res: Record<any, any>) => {
              if (res.code == '1') {
                this.$message.success(res.message);
                this.getFormListData();
              } else {
                this.$message.error(res.message);
              }
            })
            .finally(() => {});
        })
        .catch(() => {});
    }
  }

  /**
   * 编辑表单
   * @param row
   */
  editForm(row) {
    getFormConfig({ id: row.link.id }).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        // 表单
        this.formId = row.link.id;
        StoreIndex.commit('SET_FORM_CONFIG', res.data);
        this.$nextTick(() => {
          this.formConfigDiglogVisible = true;
        });
      }
    });
  }

  /**
   * 设计表单
   * @param row
   */
  designForm(row) {
    this.$router.push({
      path: '/form/design',
      query: { formId: row.link.id }
    });
  }

  /**
   * 发布表单
   * @param row
   */
  releaseForm(row) {
    this.$confirm(this.$t('lang_are_you_sure_to_release_it') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    }).then(() => {
      AppApi.publishAppLink(row.id).then((res: Record<string, any>) => {
        if (res.code == '1') {
          this.changeTab();
        } else {
          this.$message.error(res.message);
        }
      });
    });
  }

  /**
   * 历史版本
   * @param row
   */
  historyForm(row) {
    this.$router.push({ path: '/form/history', query: { lid: row.id } });
  }

  /**
   * 升版
   * @param row
   */
  upgradeForm(row) {
    if (row) {
      this.$confirm(this.$t('lang_are_you_sure_to_release_it') as string, this.$t('lang_tips') as string, {
        confirmButtonText: this.$t('lang_determine_') as string,
        cancelButtonText: this.$t('lang_cancel_') as string,
        type: 'warning'
      }).then(() => {
        AppApi.upgradeAppLink(row.id)
          .then((res: Record<string, any>) => {
            if (res.code == '1') {
              this.changeTab();
            } else {
              this.$message.error(res.message);
            }
          })
          .finally(() => {});
      });
    }
  }

  /**
   * 获取应用流程列表
   */
  getFlowListData() {
    AppApi.getApplicationLinkProcessList({ applicationId: this.appId }).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        this.flowList = res.data;
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 显示流程历史数据
   * @param row
   */
  historyFlow(row) {
    this.$router.push({ path: '/flow/history', query: { lid: row.id } });
  }

  /**
   * 流程设计
   * @param row
   */
  designFlow(row) {
    this.$router.push({
      path: '/flow/design',
      query: {
        id: row.link.id
      }
    });
  }

  /**
   * 发起流程
   * @param row
   */
  startupFlow(row) {
    this.$router.push({
      path: `/flow/start`,
      query: {
        id: row.link.id
      }
    });
  }

  /**
   * 升级流程
   */
  upgradeFlow(row) {
    if (row) {
      this.$confirm(this.$t('lang_are_you_sure_to_release_it') as string, this.$t('lang_tips') as string, {
        confirmButtonText: this.$t('lang_determine_') as string,
        cancelButtonText: this.$t('lang_cancel_') as string,
        type: 'warning'
      }).then(() => {
        AppApi.upgradeAppLink(row.id).then((res: Record<string, any>) => {
          if (res.code == '1') {
            this.changeTab();
          } else {
            this.$message.error(res.message);
          }
        });
      });
    }
  }

  /**
   * 编辑流程
   * @param row
   */
  editFlow(row) {
    this.currFlowData = row.link;
    if (this.currFlowData) {
      this.currFlowData.id = row.link.id;
    }
    this.flowConfigDialogVisible = true;
  }

  /**
   * 编辑流程基础信息
   * @param row
   */
  editFlowBaseInfo(row) {
    console.log(row);
    this.currFlowData = row.link;
    if (this.currFlowData) {
      this.currFlowData.id = row.link.id;
    }
    this.flowBaseInfoConfigDialogVisible = true;
  }

  /**
   * 删除流程
   * @param row
   */
  deleteFlow(row) {
    if (row) {
      this.$confirm(this.$t('lang_whether_or_not_to_delete') as string, this.$t('lang_tips') as string, {
        confirmButtonText: this.$t('lang_determine_') as string,
        cancelButtonText: this.$t('lang_cancel_') as string,
        type: 'warning'
      })
        .then(() => {
          AppApi.deleteAppLink(row.id)
            .then((res: Record<any, any>) => {
              if (res.code == '1') {
                this.$message.success(res.message);
                this.getFlowListData();
              } else {
                this.$message.error(res.message);
              }
            })
            .finally(() => {});
        })
        .catch(() => {});
    }
  }

  /**
   * 获取打印模板
   */
  getReportList() {
    let params = { applicationId: this.appId };
    ReportApi.getReportList(params).then((res: any) => {
      if (res.code == '1' && res.data) {
        this.reportList = res.data;
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 编辑流程
   * @param row
   */
  editReport(row) {
    this.reportId = row.id;
    this.reportConfigDiglogVisible = true;
  }

  /**
   * 删除流程
   * @param row
   */
  removeReport(row) {
    if (row) {
      this.$confirm(this.$t('lang_whether_or_not_to_delete') as string, this.$t('lang_tips') as string, {
        confirmButtonText: this.$t('lang_determine_') as string,
        cancelButtonText: this.$t('lang_cancel_') as string,
        type: 'warning'
      }).then(() => {
        ReportApi.removeReport({ applicationPrintId: row.id }).then((res: Record<any, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            this.getReportList();
          } else {
            this.$message.error(res.message);
          }
        });
      });
    }
  }

  /**
   * 监听tabs切换
   */
  changeTab() {
    let tabsActiveName = this.tabsActiveName;
    if (tabsActiveName === 'form') {
      this.getFormListData();
    } else if (tabsActiveName === 'flow') {
      this.getFlowListData();
    } else if (tabsActiveName === 'report') {
      this.getReportList();
    }
  }

  /**
   * 返回应用管理列表
   */
  callback() {
    this.$router.push({ name: 'Application' });
  }
}
